.contact_section_container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
}
.container_container {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px;
  margin: 50px 0 50px 0;
}
.gmap {
  display: flex;
  width: 100%;
  height: 327px;
  margin-bottom: 27px;
}
.google_map_location {
  width: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.basic_contact_user_form {
  width: 50%;
  padding: 50px;
  border-radius: 9px;
  background: #fff;
}
.clinic_time_table h2 span {
  margin-right: 9px;
}
.clinic_time_table {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 9px;
}
.d_and_c {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  height: 115px;
}
.direction_to_clinic,
.call_to_clinic {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 45px;
  outline: 2px solid #003380;
}

.direction_to_clinic a,
.call_to_clinic a {
  text-decoration: none;
  font-size: 15px;
  color: #003380;
  font-weight: 500;
  text-transform: uppercase;
}

.clinic_timing {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid;
  height: 60px;
  padding: 12px;
}
.copyright_footer {
  color: #fff;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: #141b2c;
  flex-wrap: wrap;
}
#clinic_name {
  color: #0396fe;
  margin: 6px;
  text-decoration: none;
  font-weight: bold;
}
.copyright_footer span {
  margin-left: 9px;
  margin-right: 9px;
}
@media screen and (min-width: 1024px) {
  .container_container {
    width: 90%;
    height: 617px;
  }
  .gmap {
    width: 100%;
    height: 518px;
    border-radius: 9px;
  }
  .basic_contact_user_form {
    padding: 25px;
    width: 490px;
    margin-right: 20px;
  }
  .google_map_location {
    width: 80%;
    margin-bottom: 30px;
  }
  .clinic_timing {
    height: 52px;
  }
}

@media screen and (max-width: 1023px) {
  .container_container {
    width: 100%;
    box-shadow: none;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .container_container {
    flex-direction: column;
  }
  .google_map_location {
    width: 100%;
  }
  .basic_contact_user_form {
    width: 100%;
    /* background: #d0d0d078; */
  }
}

@media screen and (max-width: 480px) {
  .basic_contact_user_form {
    padding: 20px;
  }
  .container_container {
    margin-top: 20px;
  }
  .d_and_c {
    justify-content: space-evenly;
    height: 153px;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .direction_to_clinic,
  .call_to_clinic {
    width: 80%;
  }
  .copyright_footer {
    width: 100%;
    display: flex;
    height: 72px;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-bottom: 36px;
  }
  .copyright_footer p {
    width: 80%;
    text-align: center;
  }
  .contact_section_container {
    height: 100%;
    padding: 30px 0;
  }
}

.app_container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #e5e5e5;
}

/* ::-webkit-input-placeholder {
  color: #000;
} */
.upper_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  width: 700px;
  height: 341px;
  background: #fff;
  box-shadow: 0 0 25px 0 #cbcbcbbf;
  border-radius: 6px;
}
hr {
  border-bottom: 1px solid rgb(189, 189, 189);
}
.appointment_booking_section {
  display: flex;
  flex-direction: column;
  width: 650px;
  height: 279px;
  padding: 15px 20px;
  align-content: center;
  justify-content: space-evenly;
}
.booking_header {
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.booking_header span {
  font-size: 24px;
  font-weight: 600;
  color: #bb2d0a;
}
.booking_header i {
  color: #bb2d0a !important;
}
.about_info {
  text-align: center;
  font-size: 18px;
}
.booking_bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
}
.booking_bottom h3 {
  font-size: 21px;
  color: green;
}
.booking_bottom h4 {
  font-weight: 500;
}
.upper_container_header {
  align-items: center;
  display: flex;
  width: 85%;
  gap: 10px;
  margin-bottom: 45px;
}
.booking_slot_section a {
  text-decoration: none;
}
.booking_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: row;
}

.book_slot {
  width: 223px;
  text-align: center;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 2px solid green;
  color: green;
  font-weight: 600;
  transition: ease-in-out 0.3s;
  border-radius: 9px;
  cursor: pointer;
}
.appointment_booking_container {
  display: flex;
  flex-direction: column;
}
.booking_slot_section:active {
  transform: scale(0.97);
}

@media screen and (max-width: 720px) {
  .upper_container {
    width: 90%;
  }
  .booking_container {
    flex-direction: column;
    height: 90px;
    gap: 20px;
    margin-top: 30px;
  }
  .appointment_booking_container {
    margin-bottom: 10px;
  }
  .appointment_booking_section {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .upper_container {
    height: 454px;
  }
  .booking_header {
    align-items: baseline;
  }
  .booking_header i {
    margin-bottom: 73px;
  }
}

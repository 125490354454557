@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&display=swap");

* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}
body {
  max-height: 100vh;
  max-width: 100vw;
  font-family: Poppins, sans-serif;
}
input {
  font-family: "Josefin Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-input-placeholder {
  color: #fff;
}
